import apiClient from '@/services/axios'

export const getListChangeRequest = async payload => {
  // console.log(payload, '<<<PAYLOAD')
  const res = await apiClient.get(`/helpdesk/request`, { params: payload })
  if (res) {
    return res.data
  } else {
    return []
  }
}
export const getChangeRequestById = async (id, payload) => {
  // console.log(payload, '<<<PAYLOAD')
  const res = await apiClient.get(`/helpdesk/request/${id}`)
  if (res) {
    return res.data
  } else {
    return []
  }
}
export const editChangeRequestById = async (id, payload) => {
  // console.log(payload, '<<<PAYLOAD')
  const res = await apiClient.put(`/helpdesk/request/${id}`, payload)
  if (res) {
    return res.data
  } else {
    return []
  }
}

export const createChangeRequest = async payload => {
  const res = await apiClient.post('/helpdesk/request', payload)
  if (res) {
    return res.data
  } else {
    return null
  }
}

export const editOrCreateTroubleshoot = async payload => {
  const res = await apiClient.post('/helpdesk/troubleshoot', payload)
  if (res) {
    return res.data
  } else {
    return null
  }
}
export const editOrCreateAddition = async payload => {
  const res = await apiClient.post('/helpdesk/addition', payload)
  if (res) {
    return res.data
  } else {
    return null
  }
}
export const editOrCreateDismantle = async payload => {
  const res = await apiClient.post('/helpdesk/dismantle', payload)
  if (res) {
    return res.data
  } else {
    return null
  }
}
export const editOrCreateTesting = async payload => {
  const res = await apiClient.post('/helpdesk/testing', payload)
  if (res) {
    return res.data
  } else {
    return null
  }
}

export const getListAdditionQuestion = async () => {
  const res = await apiClient.get('/helpdesk/addition/question')
  if (res) {
    return res.data
  } else {
    return []
  }
}
export const getListDismantlingQuestion = async () => {
  const res = await apiClient.get('/helpdesk/dismantle/question')
  if (res) {
    return res.data
  } else {
    return []
  }
}
