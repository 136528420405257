import apiClient from '@/services/axios'
const token = localStorage.getItem('accesstoken')

const getAddress = async data => {
  const res = await apiClient.post('/potensi/getAddress', data)
  if (res) {
    return res.data
  } else {
    return []
  }
}

const getAll = async params => {
  const res = await apiClient({
    url: '/potensi/getAll',
    method: 'GET',
    params: params,
  })
  if (res) {
    return res.data
  } else {
    return []
  }
}

const searchPotensi = async potensi => {
  const res = await apiClient.get('/potensi/searchPotensi?search=' + potensi)
  if (res) {
    return res.data
  } else {
    return []
  }
}

const getDistinct = async data => {
  const res = await apiClient.get('/potensi/getDistinct')
  if (res) {
    return res.data
  } else {
    return []
  }
}

const getAllPendings = async name => {
  const res = await apiClient.get('/potensi/getPendings?name=' + name)
  if (res) {
    return res.data
  } else {
    return []
  }
}

const getOne = async id => {
  const res = await apiClient.get('/potensi/getOne?id=' + id)
  if (res) {
    return res.data
  } else {
    return []
  }
}

const update = async data => {
  const res = await apiClient.put('/potensi/update', data)
  if (res) {
    return res.data
  } else {
    return []
  }
}

const updateNasabah = async data => {
  const res = await apiClient.put('/potensi/updateNasabah', data)
  if (res) {
    return res.data
  } else {
    return []
  }
}

const remove = async id => {
  const res = await apiClient.delete('/potensi/delete?id=' + id)
  if (res) {
    return res.data
  } else {
    return []
  }
}

const getAllUsers = async data => {
  const res = await apiClient.get('/users/getAll')
  if (res) {
    return res.data
  } else {
    return []
  }
}

const createUser = async data => {
  const res = await apiClient.post('/users/register', data)
  if (res) {
    return res.data
  } else {
    return []
  }
}

const updateUser = async data => {
  const res = await apiClient.put('/users/update', data)
  if (res) {
    return res.data
  } else {
    return []
  }
}

const updatePassword = async data => {
  const res = await apiClient.put('/users/updatePassword', data)
  if (res) {
    return res.data
  } else {
    return []
  }
}

const removeUser = async (id, accesstoken) => {
  const res = await apiClient.delete(`/users/delete?id=${id}&accesstoken=${accesstoken}`)
  if (res) {
    return res.data
  } else {
    return []
  }
}

const getAllKanwil = async () => {
  const res = await apiClient.get(`/kantor/getAllKanwil`)
  if (res) {
    return res.data
  } else {
    return []
  }
}

const getAllKC = async () => {
  const res = await apiClient.get(`/kantor/getAllKC`)
  if (res) {
    return res.data
  } else {
    return []
  }
}

const getOneKC = async id => {
  const res = await apiClient.get(`/kantor/getOneKC?id=${id}`)
  if (res) {
    return res.data
  } else {
    return []
  }
}

export {
  getAddress,
  getAll,
  getOne,
  getDistinct,
  createUser,
  updateNasabah,
  remove,
  getAllUsers,
  getAllKanwil,
  getAllKC,
  getOneKC,
  updateUser,
  updatePassword,
  update,
  searchPotensi,
  removeUser,
  getAllPendings,
}
