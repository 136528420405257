import apiClient from '@/services/axios'

export const getAssets = async (
  payload,
  customerId = '',
  locationId = '',
  roomId = '',
  status = '',
  userId = '',
  role = '',
  name,
) => {
  // console.log(roomId)
  const res = await apiClient.get(`/admin/assets`, {
    params: {
      ...payload,
      customerId: customerId !== 'Filter Customer' ? customerId : '',
      locationId: locationId !== 'Filter Lokasi' ? locationId : '',
      roomId: roomId !== 'Filter Ruang' ? roomId : '',
      status: status !== 'Filter Status Asset' ? status : '',
      role,
      name,
      userId: userId ? userId : '',
    },
  })
  // const res = await apiClient.get(`/admin/assets?type=${type}`)
  if (res) {
    return res.data
  } else {
    return []
  }
}
export const getAssetsById = async id => {
  const res = await apiClient.get(`/admin/assets/${id}`)
  if (res) {
    return res.data
  } else {
    return []
  }
}
export const getAssetCategory = async () => {
  const res = await apiClient.get('/admin/assetsCategory')
  if (res) {
    return res.data
  } else {
    return []
  }
}
export const editAssetsById = async (id, payload) => {
  console.log('disinicuy', id)
  const res = await apiClient.put(`/admin/assets/${id}`, payload)
  if (res) {
    return res.data
  } else {
    return []
  }
}
export const editStatusAssetsById = async (id, payload) => {
  // console.log('disinicuy', id)
  const res = await apiClient.patch(`/admin/assets/${id}`)
  if (res) {
    return res.data
  } else {
    return []
  }
}
export const postAssets = async payload => {
  const res = await apiClient.post('/admin/assets', payload)
  if (res) {
    return res.data
  } else {
    return null
  }
}

export const getCustomer = async () => {
  const res = await apiClient.get('/admin/customers')
  if (res) {
    return res.data
  } else {
    return []
  }
}
export const getCategories = async () => {
  const res = await apiClient.get('/admin/category/assets')
  if (res) {
    return res.data
  } else {
    return []
  }
}
export const postInfrastructure = async payload => {
  const res = await apiClient.post('/admin/assets/infrastructure', payload)
  if (res) {
    return res.data
  } else {
    return []
  }
}
