import apiClient from '@/services/axios'

export const getAllUser = async username => {
  console.log(username)
  const res = await apiClient.get('/admin/users', { params: { username } })
  if (res) {
    return res.data
  } else {
    return []
  }
}
