import apiClient from '@/services/axios'

export const getVendors = async name => {
  console.log(name)
  const res = await apiClient.get('/admin/vendors', { params: { name } })
  if (res) {
    return res.data
  } else {
    return []
  }
}
export const getVendorsById = async id => {
  const res = await apiClient.get(`/admin/vendors/${id}`)
  if (res) {
    return res.data
  } else {
    return []
  }
}
export const postVendor = async payload => {
  const res = await apiClient.post('/admin/vendors', payload)
  if (res) {
    return res.data
  } else {
    return []
  }
}
export const putVendor = async (payload, id) => {
  const res = await apiClient.put(`/admin/vendors/${id}`, payload)
  if (res) {
    return res.data
  } else {
    return []
  }
}
